// prettier-ignore
<!-- prettier-ignore -->
<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("main.welcome") }}
            </h1>
            <h4>
              {{ $t("main.claim") }}
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-services section-features-2">
        <div class="container" style="padding-bottom: 30px">
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="schedule">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.officeHours") }}
                </h4>
                <div slot="content" class="description">
                  <p style="font-weight: bold">
                    <a href="tel:+493512847014" style="color: #ff007f"
                      ><i class="material-icons">phone</i> 0351 / 284 7014</a
                    >
                  </p>
                  <p>
                    <a href="https://wa.me/4915678576056" style="color: #000000"
                      ><img
                        src="../assets/img/wa.png"
                        width="24"
                        style="width: 24px"
                        alt="WhatsApp icon"
                      />
                      WhatsApp&nbsp;</a
                    >
                    <a
                      href="https://m.me/zahnarztpraxis.heindel"
                      style="color: #000000"
                      ><img
                        src="../assets/img/fb.png"
                        width="24"
                        style="width: 24px"
                        alt="Facebook Messenger icon"
                      />
                      Messenger</a
                    >
                  </p>
                  <p>
                    <a
                      href="mailto:info@zahnarztpraxis-heindel.de?subject=Terminanfrage"
                      style="color: #000000"
                      ><i class="material-icons">email</i>
                      info@zahnarztpraxis-heindel.de</a
                    >
                  </p>
                  <p style="font-weight: bold; color: #fff">
                    <md-button class="md-primary md-round"
                      ><router-link :to="$i18nRoute({ name: 'bookOnline' })"
                        ><i class="material-icons">book_online</i
                        ><span style="font-weight: bold; color: #fff">{{
                          $t("menu.bookOnline")
                        }}</span></router-link
                      >
                    </md-button>
                    <md-button class="md-info md-round"
                      ><a href="https://gonelly.de/zah" target="nelly"><i class="material-icons">edit_document</i
                        ><span style="font-weight: bold; color: #fff">{{
                          $t("menu.anamnesis")
                        }}</span></a
                      >
                    </md-button>
                  </p>
                  <md-table
                    v-model="tableDataOpeningHours"
                    class="table-striped"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell :md-label="$t('common.weekday')">
                        {{ item.day }}
                      </md-table-cell>
                      <md-table-cell :md-label="$t('common.time')">
                        {{ item.slot }}
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                  <br />
                  <!-- <div class="alert " style="background-color:gray; cursor:pointer;" @click="letItSnow(); toggleSound();">
        <div class="container">
          <div class="alert-icon">
            <md-icon>ac_unit</md-icon>
          </div>
          <b>{{ $t('common.xmasBreak')}}</b><br/>{{ $t('common.xmasClosed')}}
          <div class="music-player">
          <div class="audioHint" style="display:none; padding-top: 20px; font-size:12px; color:#fff!important;">Now playing: &quot;<a :href="require('@/assets/audio/Dan_Lerch_-_09_-_O_Tannenbaum.mp3')" style="color:#fff!important; text-decoration: underline;" target="_blank">O Tannenbaum</a>&quot; by <a style="color:#fff!important; text-decoration: underline;" href="https://freemusicarchive.org/music/Dan_Lerch#contact-artist" target="_blank">Dan Lerch</a><br />(License: <a style="color:#fff!important; text-decoration: underline;" href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">CC BY-NC-SA 4.0</a>)</div>
          <audio
            ref="audio"
            src="@/assets/audio/Dan_Lerch_-_09_-_O_Tannenbaum.mp3"
            type="audio/mp3"
            loop
            id="audio"
            muted            
          ></audio>
          <div @click="toggleSound()" class="toggle-sound paused"></div>
        </div>
        </div><md-tooltip md-direction="bottom">O Tannenbaum... ♪ ♫</md-tooltip>
      </div> //-->

                  <p className="{classes.cardDescription}">
                    {{ $t("common.appointmentsByArrangement") }}
                  </p>
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Außerhalb unserer Sprechzeiten ist der
                      <a
                        href="https://www.zahnaerzte-in-sachsen.de/app/patienten/notfalldienst/ort/Dresden,%20Stadt/list"
                        >Notdienst</a
                      >
                      für Sie da!
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      Outside our office hours, the
                      <a
                        href="https://www.zahnaerzte-in-sachsen.de/app/patienten/notfalldienst/ort/Dresden,%20Stadt/list"
                        >emergency service</a
                      >
                      is there for you!
                    </p>
                  </div>

                  <p>{{ $t("common.weAlsoSpeak") }}</p>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="group">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.team") }}
                </h4>
                <div slot="content" class="description">
                  <div style=""><!-- interval="4000" -->
                    <el-carousel
                      trigger="click"
                      arrow="never"
                      indicator-position="none"
                      height="200px"
                      style="max-height: 200px; overflow: hidden"
                    >
                      <el-carousel-item
                        v-for="item in carousel"
                        :key="item.image"
                        height="200px"
                        style="max-height: 200px; vertical-align: bottom"
                      >
                        <h4
                          class="carousel-caption"
                          style="
                            top: 130px;
                            opacity: 50%;
                            color: #000 !important;
                            background-color: whitesmoke;
                            height: 200px;
                            padding-top: 0px !important;
                          "
                        >
                          <router-link :to="$i18nRoute({ name: 'team' })"
                            ><i
                              class="material-icons"
                              style="color: #000 !important"
                              >badge</i
                            ><span style="color: #000">
                              {{ item.title }}
                            </span></router-link
                          >
                        </h4>
                        <router-link :to="$i18nRoute({ name: 'team' })">
                          <img :src="item.image" :alt="item.title" />
                        </router-link>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <br />
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Unser Praxisteam kümmert sich mit Leidenschaft zum Beruf
                      um Ihre gute Behandlung.
                    </p>
                    <p>
                      Bei uns arbeiten erfahrene Zahnärztinnen, Zahnmedizinische
                      Fachangestellte (ZFA), Zahntechnikermeisterinnen,
                      Behandlungsassistenzen,
                      <!--Zahnmedizinische Prophylaxeassistenzen (ZMP), Zahnmedizinische
                      Verwaltungsassistenzen (ZMV),-->
                      Auszubildende und Empfangsmitarbeiterinnen.
                    </p>
                    <p>
                      Ergänzt werden wir durch externe Experten, z.B. bei der
                      3D-Modellierung von Zahnersatz für die
                      Keramik-Fräsmaschine und Spezialisten für komplexe
                      implantologische Fälle.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      Our practice team takes care of your good treatment with
                      passion for their profession.
                    </p>
                    <p>
                      We have experienced dentists, dental assistants (ZFA),
                      master dental technician,
                      <!-- dental administrative assistants (ZMV), -->
                      trainee and receptionists.
                    </p>
                    <p>
                      We are supplemented by external experts, e.g. in the 3D
                      modeling of dentures for the ceramic milling machine and
                      specialists for complex implantological cases.
                    </p>
                  </div>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'team' })">{{
                        $t("menu.team")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="commute">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.appointmentAndArrival") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Sie finden uns im
                      <span style="font-weight: bold !important"
                        >Gesundheitszentrum Dresden-Prohlis</span
                      >
                      im 3. Obergeschoss auf der linken Seite:
                    </p>
                    <!-- <p style="font-weight: strong; text-decoration: underline;">Postanschrift:</p> -->
                    <p class="card-content">
                      <router-link
                        :to="$i18nRoute({ name: 'location' })"
                        style="color: #000000"
                        >Zahnarztpraxis Heindel<br />Georg-Palitzsch-Straße
                        12<br />01239 Dresden</router-link
                      >
                    </p>
                    <p>
                      Unsere Praxis ist auch für Patienten mit eingeschränkter
                      Beweglichkeit bequem über den Aufzug erreichbar.
                    </p>
                    <p>Parkmöglichkeiten finden Sie vor dem Haus.</p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      You can find us in the
                      <span style="font-weight: bold !important"
                        >Dresden-Prohlis Health Center</span
                      >
                      on the 3rd floor on the left:
                    </p>
                    <!-- <p style="font-weight: strong; text-decoration: underline;">Postanschrift:</p> -->
                    <p class="card-content">
                      <router-link
                        :to="$i18nRoute({ name: 'location' })"
                        style="color: #000000"
                        >Zahnarztpraxis Heindel<br />Georg-Palitzsch-Straße
                        12<br />01239 Dresden</router-link
                      >
                    </p>
                    <p>
                      Our practice can also be easily reached via the elevator
                      for patients with limited mobility.
                    </p>
                    <p>Parking is available in front of the house.</p>
                  </div>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'location' })">{{
                        $t("menu.howToReachUs")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$i18nRoute({ name: 'accessibility' })"
                        >{{ $t("menu.accessibility") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <!-- https://www.dresden.de/de/leben/gesundheit/kindergesundheit/zaehne.php -->
            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="coronavirus">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.corona") }}
                </h4>
                <div slot="content" class="description">
                  <p>
                    <badge type="rose" class="badge">{{
                      $t("common.stillOpen")
                    }}</badge>
                  </p>

                  <div v-if="$i18n.locale == 'de'">
                    <!-- <p>
                      Gemäß § 28b Abs. 1 des
                      <a
                        itemprop="quarantineGuidelines"
                        content="https://www.gesetze-im-internet.de/ifsg/index.html"
                        href="https://www.gesetze-im-internet.de/ifsg/index.html"
                        >Infektionsschutzgesetz - IfSG</a
                      >
                      dürfen Sie derzeit unsere Praxis nur betreten,
                      wenn Sie eine  <i
                          class="material-icons"
                          style="color: #ff007f; vertical-align: middle"
                          >masks</i
                        > Atemschutzmaske (FFP2 oder vergleichbar) tragen.
                    </p> -->
                    <p>
                      <strong>
                        Bitte suchen Sie uns nicht persönlich auf, wenn Sie
                        Krankheitssymptome aufweisen oder wissentlich Kontakt
                        mit einer auf den Coronavirus positiv getesteten Person
                        hatten.</strong
                      >
                    </p>
                    <p>
                      Entsprechende Schutzausrüstung ist vorhanden und
                      <strong
                        >alle Behandlungen und Vorsorgeuntersuchungen können
                        durchgeführt werden</strong
                      >.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <!-- <p>
                      In accordance with Section 28b (1) of the current
                      <a
                        itemprop="quarantineGuidelines"
                        content="https://www.gesetze-im-internet.de/ifsg/index.html"
                        href="https://www.gesetze-im-internet.de/ifsg/index.html"
                        >Infection Protection Act - IfSG</a
                      >
                      it is mandatory that you wear a <i
                          class="material-icons"
                          style="color: #ff007f; vertical-align: middle"
                          >masks</i
                        >respiratory mask (FFP2 or comparable)
                      when visiting our practice.
                    </p> -->
                    <p>
                      <strong
                        >Please do not visit us personally if you have symptoms
                        of illness or knowingly had contact with someone who
                        tested positive for the coronavirus.</strong
                      >
                    </p>
                    <p>
                      Appropriate protective equipment is available and
                      <strong
                        >all treatments and preventive examinations can be
                        carried out</strong
                      >.
                    </p>
                  </div>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <div v-if="$i18n.locale == 'de'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'corona' })"
                          >Aktuelle Informationen zum Umgang unserer Praxis mit
                          dem Coronavirus</router-link
                        >
                      </li>                      
                    </div>
                    <div v-if="$i18n.locale == 'en'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'corona' })"
                          >Current informationen on how our practice deals with
                          the coronavirus</router-link
                        >
                      </li>                      
                    </div>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="check_circle">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.firstVisit") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Für Ihren ersten Besuch bitten wir Sie Folgendes
                      mitzubringen:
                    </p>
                    <ul>
                      <li>
                        Ihre Versichertenkarte / bei Privatpatienten Ihren
                        Personalausweis
                      </li>
                      <li>
                        Medizinische Vorbefunde und Röntgenbilder (soweit
                        vorhanden)
                      </li>
                      <li>
                        Eine Auflistung Ihrer Medikamente einschließlich
                        Dosierungen
                      </li>
                    </ul>
                    <p>
                      <b>Noch Fragen?</b> Rufen Sie uns einfach an oder
                      schreiben Sie uns eine Nachricht.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      For your first visit, we ask you to bring the following
                      with you:
                    </p>
                    <ul>
                      <li>
                        Your insurance card / for private patients your identity
                        card
                      </li>
                      <li>
                        Medical preliminary findings and x-rays (if available)
                      </li>
                      <li>A list of your medications including dosages</li>
                    </ul>
                    <p>
                      <b>Any questions?</b> Just give us a call or send us a
                      message.
                    </p>
                  </div>

                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'preparation' })">{{
                        $t("menu.preparation")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$i18nRoute({ name: 'communication' })"
                        >{{ $t("menu.communication") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <!-- Banner - BEGIN 
<div class="md-layout-item md-size-100 md-small-size-100">
  <div class="alert alert-success">
        <div class="container">
          <button
            type="button"
            aria-hidden="true"
            class="close"
            @click="event => removeNotify(event, 'alert-success')"
          >
            <md-icon>clear</md-icon>
          </button>
          <div class="alert-icon">
            <md-icon>masks</md-icon>
          </div>
          <b> Bitte beachten Sie, </b> dass das Tragen einer Mund-Nasen-Bedeckung gemäß aktueller <a href="https://www.coronavirus.sachsen.de/" style="color:#fff;text-decoration:underline; ">Corona-Schutz-Verordnung der sächsischen Staatsregierung</a> derzeit in unserer Praxis Pflicht ist.
        </div>
  </div>
</div>
 Banner - END -->


            <!-- 
            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="work">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.takeover") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Wir haben zum 1. Juli 2020 die Praxen von
                      <strong>Dr. med. Reingard Ittlinger</strong> und
                      <strong>Dr. med. Elke Stiller</strong> übernommen (beide
                      im Ruhestand) und sind
                      <strong
                        >weiterhin am bekannten Ort für die ehemaligen Patienten
                        da</strong
                      >.
                    </p>
                    <p>
                      Die Archivdaten und Röntgenaufnahmen werden in unserer
                      neuen Praxis in einem besonders gesicherten Archiv
                      vorgehalten und sind nach erteilter
                      Einverständniserklärung durch die Patientin / den
                      Patienten weiterhin nutzbar.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      On July 1st, 2020 we took over the practices of
                      <strong>Dr. med. Reingard Ittlinger</strong> and
                      <strong>Dr. med. Elke Stiller</strong> (both retired) and
                      are
                      <strong
                        >still there for the former patients at the familiar
                        location</strong
                      >.
                    </p>
                    <p>
                      The archive data and X-rays are kept in a specially
                      secured archive in our new practice and can still be used
                      after the patient has given his / her consent.
                    </p>
                  </div>

                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <div v-if="$i18n.locale == 'de'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'takeover' })"
                          >Details zur Übernahme und was das für Sie als Patient
                          bedeutet</router-link
                        >
                      </li>
                    </div>
                    <div v-if="$i18n.locale == 'en'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'takeover' })"
                          >Details about the takeover and what that means for
                          you as a patient</router-link
                        >
                      </li>
                    </div>
                  </ul>
                </div>
              </info-areas>
            </div>
            -->

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="psychology">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.philosophy") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Die Erhaltung Ihrer Zahngesundheit und Erfüllung Ihrer
                      ästhetischen Ansprüche ist das Ziel unserer Arbeit. Unser
                      Praxisteam setzt dabei alles daran, Ihnen als Patient eine
                      entspannte Behandlung und eine umfassende Beratung zu
                      gewährleisten. Vertrauensvoll und mit viel Engagement
                      finden wir eine optimale Lösung für Ihre individuelle
                      Zahnsituation.
                    </p>
                    <p>
                      Die moderne Zahnheilkunde bietet ein breites Spektrum
                      verschiedener Behandlungsmöglichkeiten. Verschaffen Sie
                      sich auf unserer Website einen ersten Eindruck und
                      informieren Sie sich über unser Leistungsspektrum, welches
                      unterschiedliche Bereiche der Zahnheilkunde abdeckt.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      Maintaining your dental health and meeting your aesthetic
                      demands is the goal of our work. Our practice team does
                      everything in its power to guarantee you, the patient,
                      relaxed treatment and comprehensive advice. With a lot of
                      trust and commitment, we will find the optimal solution
                      for your individual dental situation.
                    </p>
                    <p>
                      Modern dentistry offers a wide range of different
                      treatment options. Get a first impression on our website
                      and find out more about our range of services, which
                      covers different areas of dentistry.
                    </p>
                  </div>

                  <!-- <router-link :to="$i18nRoute({ name: 'takeover'})">Mehr erfahren...</router-link>-->
                </div>
              </info-areas>
            </div>

            <!-- corona information was here -->

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="school">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.trainingCompany") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>Wir bilden aus und begleiten derzeit mehrere Zahnmedizinische Fachangestellte (ZFA) auf dem Weg in das Berufsleben.</p>
                    <!--<p>Darüber hinaus gewähren wir unserem Personal jedes Jahr Sonderurlaub für Trainings &amp; Weiterbildungen und erfüllen die berufständische Fortbildungsverpflichtung von mindestens 250  Fortbildungspunkten (CME-Punkte) innerhalb von 5 Jahren.</p>-->
                    <p>
                      Wir gewähren unserem Personal jedes Jahr Sonderurlaub für
                      Trainings &amp; Weiterbildungen und erfüllen die
                      berufständische Fortbildungsverpflichtung von mindestens
                      250 Fortbildungspunkten (CME-Punkte) innerhalb von 5
                      Jahren.
                    </p>
                    <p>
                      Wir bieten die Möglichkeit für Praktika und Hospitationen
                      an.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>We currently employ multiple apprentices (ZFA) and support them on their way into professional life.</p>
                    <!-- <p>In addition, we grant our staff special leave every year for training and further education and fulfill the professional further education obligation of at least 250 further education points (CME points) within 5 years.</p> -->
                    <p>
                      We grant our staff special leave every year for training
                      and further education and fulfill the professional further
                      education obligation of at least 250 further education
                      points (CME points) within 5 years.
                    </p>
                    <p>
                      We offer the possibility for internships and job
                      shadowing.
                    </p>
                  </div>
                  <!--        <router-link :to="$i18nRoute({ name: 'trainingCompany'})">Mehr erfahren...</router-link> -->
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas
                info-horizontal
                icon-color="rose"
                icon="account_balance"
              >
                <h4 slot="title" class="info-title">
                  {{ $t("menu.financing") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Gerade bei sehr umfangreichen Behandlungen werden wir oft
                      nach Möglichkeiten zur Ratenzahlung gefragt. Natürlich
                      bieten wir Ihnen bequeme Finanzierungen mit unserer
                      Partnergesellschaft an.
                    </p>
                    <p>
                      Sprechen Sie uns bei Ihrem Besuch darauf an, wir helfen
                      Ihnen gerne.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      In the case of very extensive treatments in particular, we
                      are often asked about options for payment in installments.
                      Of course, we offer you convenient financing with our
                      partner company.
                    </p>
                    <p>
                      Talk to us about it during your visit, we will be happy to
                      help.
                    </p>
                  </div>

                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'financing' })">{{
                        $t("menu.financing")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="money_off">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.costtransparency") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Leider werden nicht alle Leistungen vollständig von der
                      Krankenkasse übernommen und es kann passieren, dass Sie
                      eine Zuzahlung leisten müssen.
                    </p>
                    <p>
                      Deshalb erhalten Sie <u>vor jeder Behandlung</u>, bei der
                      eine Zuzahlung notwendig werden könnte, eine mündliche
                      Beratung und eine schriftliche Mehrkostenvereinbarung,
                      welche Sie unterscheiben müssen bevor wir etwas abrechnen
                      dürfen.
                    </p>
                    <p>
                      Für eine Entscheidung können Sie sich genügend Zeit
                      nehmen. Das muss nicht im Termin direkt geschehen. Oft
                      gibt es auch unterschiedliche kosmetische Varianten, die
                      eine Auswirkung auf den Preis haben können. Wir helfen
                      Ihnen in Ruhe, die passende Lösung zu finden und werden
                      Sie niemals unter Druck setzen!
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      Unfortunately, not all services are fully covered by the
                      health insurance and you may have to make an additional
                      payment.
                    </p>
                    <p>
                      That is why you will receive verbal advice and a written
                      agreement on additional costs
                      <u
                        >before any treatment that may require additional
                        payment</u
                      >, which you must agree on before we can settle anything.
                    </p>
                    <p>
                      You can take your time to make a decision. This doesn't
                      have to happen directly in the appointment. Often there
                      are also different cosmetic variants that can have an
                      effect on the price. We will help you to find the right
                      solution in peace and will never put you under pressure!
                    </p>
                  </div>

                  <!--<p>{{ $t('common.learnMore')}}</p>
        <ul>
          <li><router-link :to="$i18nRoute({ name: 'costTransparancy'})">Kostentransparenz in meinern Zahnarztpraxis</router-link></li>
          <li><router-link :to="$i18nRoute({ name: 'costTransparancy'})">Preisvergleiche</router-link></li>
          <li><router-link :to="$i18nRoute({ name: 'costTransparancy'})">Externe Gutachten</router-link></li>
        </ul>-->
                </div>
              </info-areas>
            </div>

            <!--

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas
                info-horizontal
                icon-color="rose"
                icon="extension"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                  Externe Diensleistung
                </h4>
                <div
                  slot="content"
                  class="description"
                >
        <p>Experten für 3D Modellierung, Experten für Abrechnung, Experten für Datensicherung und Datenschutz, ...</p>
        <router-link :to="$i18nRoute({ name: 'external'})">Mehr erfahren...</router-link>
                </div>
              </info-areas>
            </div>
-->

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas
                info-horizontal
                icon-color="rose"
                icon="airline_seat_recline_extra"
              >
                <h4 slot="title" class="info-title">
                  {{ $t("menu.rooms") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Mit der Übernahme der beiden Vorgängerpraxen haben wir ein
                      umfangreiches Modernisierungsprogramm durchgeführt und
                      unter anderem neue digitale Röntgentechnik angeschaft,
                      sowie Maschinen für Ultraschallbehandlung bei
                      Parodontalerkrankungen, für PRF-Verfahren
                      (Eigenblutbehandlung) bei Knochenaufbau, CEREC für
                      Keramikkronen, uvm.
                    </p>
                    <p>
                      Auch der Wartebereich und die Rezeption wurden erneuert.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      With the takeover of the two previous practices, we have
                      implemented an extensive modernization program and, among
                      other things, purchased new digital X-ray technology, as
                      well as machines for ultrasound treatment for periodontal
                      diseases, for PRF procedures (autologous blood treatment)
                      for bone augmentation, CEREC for ceramic crowns, and much
                      more.
                    </p>
                    <p>
                      The waiting area and the reception have also been renewed.
                    </p>
                  </div>
                  <!--                <router-link :to="$i18nRoute({ name: 'rooms'})">Mehr erfahren...</router-link> -->
                </div>
              </info-areas>
            </div>
            <!-- 
            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas
                info-horizontal
                icon-color="rose"
                icon="remove_red_eye"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                  {{ $t('menu.opg')}}
                </h4>
                <div
                  slot="content"
                  class="description"
                >
        <p>KKKKK</p>
                <router-link :to="$i18nRoute({ name: 'opg'})">Mehr erfahren...</router-link>

                </div>
              </info-areas>
            </div>
-->

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="biotech">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.laboratory") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Unsere Praxis ist mit einem eigenen Labor für die
                      Fertigung von hochwertigem Zahnersatz, Prothesen &amp;
                      Reparaturen ausgestattet.
                    </p>
                    <p>
                      So können wir einfache Reparaturen schnell und
                      kostengünstig vor Ort ausführen.
                    </p>
                    <p>
                      Darüber hinaus arbeiten wir mit den folgenden
                      professionellen regionalen Zahnlaboren zusammen:
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      Our practice is equipped with its own laboratory for the
                      production of high-quality dentures, prostheses and
                      repairs.
                    </p>
                    <p>
                      This enables us to carry out simple repairs quickly and
                      cheaply on site.
                    </p>                   
                    <p>
                      In addition, we work with the following professional
                      regional dental laboratories:
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href="https://apex-dentallabor.de/" target="APEX"
                        >APEX Dentallabor</a
                      >
                      (Dresden)
                    </li>
                    <li>
                      <a
                        href="https://dental-art-dresden.de/"
                        target="dentalart"
                        >Dental Art Dresden</a
                      >
                    </li>
                    <li>
                      <a href="https://bionik-dental.de/" target="bionikdental"
                        >BIONIK-DENTAL Laboratories</a
                      >
                      (Berlin)
                    </li>
                  </ul>
                    <p style="font-weight: bold; color: #fff">
                    <md-button class="md-default md-round"
                      ><router-link :to="$i18nRoute({ name: 'laboratory' })"
                        ><i class="material-icons">biotech</i
                        ><span style="font-weight: bold; color: #fff">
                          {{ $t("menu.laboratory-learn-more") }}
                        </span></router-link
                      >
                    </md-button>
                  </p>
                  <!--

Implantologie inkl. Knochenaufbau und Weichgewebsmanagement, Endodontie, Parodontologie, ästhetische Prothetik, Füllungstherapie, Invisalign



Durch meine bisherige Arbeit in verschiedenen Praxislaboren, sowie dadurch verbundenen direkten Patientenkontakt, kann ich den geplanten Zahnersatz nach individuellen Wünschen gestalten. Die Nähe zu den Patienten ist mir sehr wichtig sowie deren Vorstellungen soweit es geht umzusetzen.

                <router-link :to="$i18nRoute({ name: 'laboratory'})">Mehr erfahren...</router-link> -->
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="3d_rotation">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.digitale-abformung") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      CEREC ist eine CAD/CAM-Methode zur Rekonstruktion von
                      Zahnrestaurationen. Damit können wir vor Ort schnell und
                      kostengünstig in nur einer Behandlung hochwertigen
                      Zahnersatz aus Keramik herstellen - mit der gleichen
                      Technik, die auch externe Dentallabore verwenden.
                    </p>
                    <p>
                      Weil dabei über eine intraorale Kamera ein 3D-Modell der
                      zu versorgenden Stelle generiert wird, kann auf den
                      Einsatz von unangenehmen Abdrücken mit Knetmasse
                      verzichtet werden.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      CEREC is a CAD/CAM method for the reconstruction of dental
                      restorations. It enables us to produce high-quality
                      ceramic dental restorations on site quickly and
                      cost-effectively in a single treatment - using the same
                      technology that external dental laboratories use.
                    </p>
                    <p>
                      Because a 3D model of the area to be restored is generated
                      via an intraoral camera, the use of unpleasant impressions
                      with modelling clay can be avoided.
                    </p>
                  </div>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <!-- <li><router-link :to="$i18nRoute({ name: 'digitale-abformung'})">Mehr erfahren:</router-link></li> -->
                    <li>
                      <a href="http://www.nureinebehandlung.de/">{{
                        $t("common.cerecvideo")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="healing">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.prf") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Mit dem PRF Verfahren reicht eine Blutabnahme, um den
                      verlorenen Knochen regenerieren zu lassen. Die Vorteile!?
                    </p>
                    <ul>
                      <li>
                        Keine allergischen Reaktionen, weil der neu gebildete
                        Knochen aus dem eigenen Blut stammt.
                      </li>
                      <li>Keine tierischen Präparate nötig</li>
                      <li>
                        Weniger kostenintensiv mit einem sicheren
                        Behandlungserfolg und vorhersehebaren Ergebnissen.
                      </li>
                    </ul>
                    <p>
                      Informieren Sie sich jetzt über das PRF-Verfahren. Wir
                      beraten Sie gern!
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      With the PRF procedure, a blood sample is sufficient to
                      regenerate the lost bone. Your advantages:
                    </p>
                    <ul>
                      <li>
                        No allergic reactions, because the newly formed bone
                        comes from the patient's own blood.
                      </li>
                      <li>No animal preparations necessary</li>
                      <li>
                        Less cost-intensive with a safe treatment success and
                        predictable results.
                      </li>
                    </ul>
                    <p>
                      Find out more about the PRF procedure now. We will be
                      happy to advise you!
                    </p>
                  </div>
                  <!-- <router-link :to="$i18nRoute({ name: 'prf'})">Mehr erfahren...</router-link> -->
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="airline_seat_recline_extra">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.professionelle-zahnreinigung-pzr-prophylaxe") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Schützen Sie Ihre Zähne vor Karies und Zahnfleischentzündungen!
                    </p>
                    <ul>
                      <li>besonders schonend und schmerzfrei dank Pulverstrahlgerät</li>
                      <li>gesundes Zahnfleisch und hellere Zähne</li>
                      <li>70% geringeres Kariesrisiko</li>
                      <li>teuren Zahnersatz vermeiden</li>
                    </ul>
                    <p>
                      Sprechen Sie uns an. Wir
                      beraten Sie gern!
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      PZR:
                    </p>
                    <ul>
                      <li>
                        No allergic reactions, because the newly formed bone
                        comes from the patient's own blood.
                      </li>
                      <li>No animal preparations necessary</li>
                      <li>
                        Less cost-intensive with a safe treatment success and
                        predictable results.
                      </li>
                    </ul>
                    <p>
                      Find out more about the PRF procedure now. We will be
                      happy to advise you!
                    </p>
                  </div>
                  <p style="font-weight: bold; color: #fff">
                      <md-button class="md-default md-round">
                        <router-link
                          :to="
                            $i18nRoute({
                              name: 'professionelle-zahnreinigung-pzr-prophylaxe',
                            })
                          "
                        >
                          <i class="material-icons">biotech</i>
                          <span style="font-weight: bold; color: #fff">
                            Jetzt über PZR informieren
                          </span>
                        </router-link>
                      </md-button>
                    </p>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="edit">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.forms") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>
                      Sie finden hier die gleichen Dokumente, die wir auch vor
                      Ort in der Praxis verwenden.
                    </p>
                    <p>
                      Informieren Sie sich in Ruhe und drucken Sie diese gern
                      aus und bringen Sie sie dann einfach zu Ihrem Termin mit.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>
                      You will find the same documents here that we use in
                      practice on site.
                    </p>
                    <P
                      >Take your time to find out more and if you want, print
                      them out, fill them out and bring them with you to the
                      treatment appointment.</P
                    >
                  </div>

                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'forms' })">{{
                        $t("menu.forms")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas info-horizontal icon-color="rose" icon="person_add">
                <h4 slot="title" class="info-title">
                  {{ $t("menu.joboffers") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>Möchten Sie ein Teil unseres Teams werden?</p>
                    <p>
                      Dann bewerben Sie sich bei uns. Wir freuen uns über die
                      Zusendung Ihrer aussagekräftigen Bewerbung bevorzugt per
                      E-Mail an:<br /><a
                        href="mailto:jobs@zahnarztpraxis-heindel.de?subject=Bewerbung"
                        style="color: #000000"
                        ><i class="material-icons">email</i>
                        jobs@zahnarztpraxis-heindel.de</a
                      >
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>Would you like to be part of our team?</p>
                    <p>
                      Then apply to us. We look forward to receiving your
                      detailed application, preferably by email to:<br /><a
                        href="mailto:jobs@zahnarztpraxis-heindel.de?subject=Bewerbung"
                        style="color: #000000"
                        ><i class="material-icons">email</i>
                        jobs@zahnarztpraxis-heindel.de</a
                      >
                    </p>
                  </div>

                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <div v-if="$i18n.locale == 'de'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'joboffers' })"
                          >Aktuelle Stellenangebote</router-link
                        >
                      </li>
                    </div>
                    <div v-if="$i18n.locale == 'en'">
                      <li>
                        <router-link :to="$i18nRoute({ name: 'joboffers' })"
                          >Current job offers</router-link
                        >
                      </li>
                    </div>
                  </ul>
                </div>
              </info-areas>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-100">
              <info-areas
                info-horizontal
                icon-color="rose"
                icon="contact_support"
              >
                <h4 slot="title" class="info-title">
                  {{ $t("menu.faq") }}
                </h4>
                <div slot="content" class="description">
                  <div v-if="$i18n.locale == 'de'">
                    <p>Manche Fragen werden uns häufiger gestellt.</p>
                    <p>
                      Wir haben diese gesammelt und liefern Ihnen die Antworten.
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <p>Some questions are asked more frequently.</p>
                    <p>
                      We have collected them and provide you with the answers.
                    </p>
                  </div>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'faq' })">{{
                        $t("menu.faq")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </info-areas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { InfoAreas, Badge } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    InfoAreas,
    Badge,
  },
  methods: {
    // myComponent.component.thml
    btnClick() {
      this.router.navigateByUrl("/bookOnline");
    },
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: this.$t("common.time8to7"),
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: this.$t("common.time8to3"),
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        },
        {
          id: 8,
          day: this.$t("common.silvester"),
          slot: this.$t("common.closed"),
        }*/,
      ];
    },
  },
  data() {
    return {
      carousel: [
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-10_small.jpg"),
          title: "D.D.S Iro Karouzaki-Heindel, Behandlungsteam",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-2_small.jpg"),
          title: "dr. med. dent. Xiaoyuan Xu, Behandlungsteam",
        },
        /*{
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-7_small.jpg"),
          title: "PD Dr. med. dent. Sarah Sonnenschein, Behandlungsteam",
        },*/
        {
          image: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-4_small.jpg"),
          title: "ZÄ Reine Karbouj, Behandlungsteam",
        },
        {
          image: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-3_small.jpg"),
          title: "ZÄ Anastasia Olschanski, Behandlungsteam",
        },
        {
          image: require("@/assets/img/zaph/12122022-1_Praxis Heindel_web-1_small.jpg"),
          title: "Andrea Landsmann (ZFA), Prophylaxe",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-ghost_small.jpg"),
          title: "Loreen Gemballa (ZFA), Prophylaxe",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-6_small.jpg"),
          title: "Kristin Uhlemann (Zahntechnikermeisterin), Dentallabor",
        },
        {
          image: require("@/assets/img/placeholder.jpg"),
          title: "Markus Klein (Zahntechnikermeister), Dentallabor",
        },
        /*{
          image: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-5_small.jpg"),
          title: "Nadine Franke (Zahntechnikerin), Dentallabor",
        },*/
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-9_small.jpg"),
          title: "Christian Heindel, Praxismanagement",
        },
        {
          image: require("@/assets/img/zaph/12122022-1_Praxis Heindel_web-3_small.jpg"),
          title: "Stefanie Wiskandt, Empfang",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-ghost_small.jpg"),
          title: "Sandra Hustig, Empfang",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-1_small.jpg"),
          title: "Elena, Behandlungsassistenz",
        },/*
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-3_small.jpg"),
          title: "Bedour, Behandlungsassistenz",
        },*/
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-8_small.jpg"),
          title: "Amir (ZFA in Ausbildung), Behandlungsassistenz",
        },
        {
          image: require("@/assets/img/zaph/Dental-bilder-bianca3.jpeg"),
          title: "Bianka Klink, Abrechnung",
        },
        /*{
          image: require("@/assets/img/zaph/28042023-1_Zahnarztpraxis Heindel_web-2_small.jpg"),
          title: "Noor, Behandlungsassistenz",
        },*/
        {
          image: require("@/assets/img/zaph/28042023-1_Zahnarztpraxis Heindel_web-4_small.jpg"),
          title: "Samira (ZFA in Ausbildung), Behandlungsassistenz",
        },
        {
          image: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-2_small.jpg"),
          title: "Zahra (ZFA in Ausbildung), Behandlungsassistenz",
        },
        {
          image: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-ghost_small.jpg"),
          title: "Hier klicken, um das ganze Team kennenzulernen...",
        },
      ],
      imageTeam: require("@/assets/img/zaph/iro-und-patient.jpg"),
      responsive: true,

      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/zaph/zaph_empfang_abends.jpg") /* visuals-0RPf107BLek-unsplash-large */,
      /*image: require("@/assets/img/zaph/socaldental-care-l0DtHcv9jfQ-unsplash.jpg"),  visuals-0RPf107BLek-unsplash-large */
    };
  },
  metaInfo() {

    var companyInfoDE = {
      "@context": "http://www.schema.org",
      "@type": "Dentist",
      "name": "Zahnarztpraxis Heindel",
      "url": "https://www.zahnarztpraxis-heindel.de",
      "logo": "https://www.zahnarztpraxis-heindel.de/favicon.png",
      "image": "https://www.zahnarztpraxis-heindel.de/img/zaph_empfang_abends.70e54fdd.jpg",
      "description": "Wir sind Ihre moderne Zahnarztpraxis mit freundlichen und erfahrenen Mitarbeitern in zentraler Lage im Gesundheitszentrum Dresden-Prohlis.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Georg-Palitzsch-Straße 12",
        "addressLocality": "Dresden",
        "addressRegion": "Sachsen",
        "postalCode": "01239",
        "addressCountry": "Deutschland"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.00375704138563",
        "longitude": "13.793029582359363"
      },
      "hasMap": "https://g.page/zahnarzt-dresden-prohlis?share",      
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday"
          ],
          "opens": "08:00",
          "closes": "19:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Friday"
          ],
          "opens": "08:00",
          "closes": "15:00"
        }
      ],
      "telephone": "+49 (0)351 284 7014",
      "sameAs": [
        "https://www.facebook.com/zahnarztpraxis.heindel?ref=website/",
        "https://www.instagram.com/zahnarzt_dresden/?ref=website",
        "https://www.linkedin.com/company/zahnarztpraxis-heindel/",
        "https://www.gesundheitszentrum-prohlis.de/Zahnaerzte/Zahnarztpraxis-Heindel"
      ],
      "acceptsReservations": "True"
    };

    var companyInfoEN = {
      "@context": "http://www.schema.org",
      "@type": "Dentist",
      "name": "Zahnarztpraxis Heindel",
      "url": "https://www.zahnarztpraxis-heindel.de",
      "logo": "https://www.zahnarztpraxis-heindel.de/favicon.png",
      "image": "https://www.zahnarztpraxis-heindel.de/img/zaph_empfang_abends.70e54fdd.jpg",
      "description": "We are your modern dental practice with friendly and experienced employees in a central location in the Dresden-Prohlis Health Center and offer you comprehensive advice and treatment in all areas of dentistry.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Georg-Palitzsch-Straße 12",
        "addressLocality": "Dresden",
        "addressRegion": "Saxony",
        "postalCode": "01239",
        "addressCountry": "Germany"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.00375704138563",
        "longitude": "13.793029582359363"
      },
      "hasMap": "https://g.page/zahnarzt-dresden-prohlis?share",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday"
          ],
          "opens": "08:00",
          "closes": "19:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Friday"
          ],
          "opens": "08:00",
          "closes": "15:00"
        }
      ],
      "telephone": "+49 (0)351 284 7014",
      "sameAs": [
        "https://www.facebook.com/zahnarztpraxis.heindel?ref=website/",
        "https://www.instagram.com/zahnarzt_dresden/?ref=website",
        "https://www.linkedin.com/company/zahnarztpraxis-heindel/",
        "https://www.gesundheitszentrum-prohlis.de/Zahnaerzte/Zahnarztpraxis-Heindel"
      ],
      "acceptsReservations": "True"
    };
    
    let scriptInternal = [];
    scriptInternal.push({
      hid: 'schemaFaq',
      json: (this.$i18n.locale=='de' ? companyInfoDE : companyInfoEN),
      type: 'application/ld+json'
    });

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Zahnarztpraxis Heindel im Gesundheitszentrum Dresden-Prohlis",
      // all titles will be injected into this template
      titleTemplate: null,
      script: scriptInternal
    }
    //<link rel="alternate" href="https://www.zahnarztpraxis-heindel.de/de/" hreflang="de-de" />
    //<link rel="alternate" href="https://www.zahnarztpraxis-heindel.de/en/" hreflang="en-us" />
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.mt-2 {
  margin-top: 1.5rem;
}

.md-table-head {
  display: none;
}
</style>
